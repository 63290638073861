<template>
  <div class="insurance-main">
    <div class="info">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-row :gutter="50">
          <el-col :span="8">
            <el-form-item label="保险对象" class="not-bottom">
              <el-select class="item-choose" v-model="dataForm.insureObject"
                         :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择"
                         @change="changeObject">
                  <el-option
                    v-for="item in dictTypeMap.insure_object"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="车牌号" prop="vehiclePlate" style="width: 100%">
              <el-input v-model="dataForm.vehiclePlate" v-if="!showVehicle" @change="changeData" :disabled="isView"></el-input>
              <el-cascader
                :disabled="isView"
                v-if="showVehicle"
                v-model="dataForm.vehiclePlate"
                :options="vehicleList"
                ref="cascaderTake"
                clearable
                filterable
                change-on-select
                @change="changeData"
                :props="{ checkStrictly: false, value: 'vehiclePlate', label: 'vehiclePlate' }"
                placeholder="请选择"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="交强险" name="交强险">
          <compulsory ref="compulsory" :row="dataForm" :todo="todo" @returnAddData="returnAddData" v-if="activeName == '交强险'"></compulsory>
        </el-tab-pane>
        <el-tab-pane label="商业险" name="商业险">
          <business ref="business" :row="dataForm" :todo="todo" @returnAddData="returnAddData"  v-if="activeName == '商业险'"></business>
        </el-tab-pane>

        <el-tab-pane label="承运人责任险" name="承运人责任险">
          <response ref="response" :row="dataForm" :todo="todo" @returnAddData="returnAddData"  v-if="activeName == '承运人责任险'"></response>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import compulsory from './card/compulsory'
import business from './card/business'
import response from './card/responsibility'
export default {
  components: {
    compulsory,
    business,
    response
  },
  data () {
    return {
      dataForm: {
        id: '',
        insureObject: null,
        vehiclePlate: null
      },
      vehicleList: [],
      todo: 'view',
      activeName: '-1',
      showVehicle: false,
      row: {}
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isView () {
      return this.todo === 'view'
    }
  },
  activated () {
    this.getVehicle()
  },
  mounted () {
    if (this.$route.params.todo) {
      this.todo = this.$route.params.todo
    }
    if (this.$route.params.row) {
      this.row = this.$route.params.row
      this.dataForm.id = this.$route.params.row.id
      this.dataForm.insureObject = this.$route.params.row.insureObject
      if (this.$route.params.row.vehiclePlate.indexOf('/') > -1) {
        this.dataForm.vehiclePlate = this.$route.params.row.vehiclePlate.split('/')
      } else {
        this.dataForm.vehiclePlate = this.$route.params.row.vehiclePlate
      }
    }
    let that = this
    setTimeout(function () {
      that.activeName = '交强险'
    })
    this.showInputType()
  },
  methods: {
    handleClick () {

    },
    returnAddData (data) {
      this.row = data
      this.dataForm.id = data.id
      this.changeData()
      // this.$refs.compulsory.update(this.dataForm)
      // this.$refs.business.init(this.dataForm)
      // this.$refs.response.init(this.dataForm)
    },
    getVehicle () {
      this.$http({
        url: this.$http.adornUrl('/vehicleInfo/findTreeList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.vehicleList = data
      })
    },
    showInputType () {
      let insureObject = this.dictTypeMap.insure_object
      this.showVehicle = false
      for (let i = 0; i < insureObject.length; i++) {
        if (this.dataForm.insureObject === insureObject[i].id && insureObject[i].code === 'vehicle') {
          this.showVehicle = true
          break
        }
      }
    },
    changeObject () {
      this.dataForm.vehiclePlate = null
      this.showInputType()
      this.changeData()
    },
    changeData () {
      if (this.activeName === '交强险') {
        this.$refs.compulsory.update(this.dataForm)
      } else if (this.activeName === '商业险') {
        this.$refs.business.update(this.dataForm)
      } else {
        this.$refs.response.update(this.dataForm)
      }
    }
  }
}
</script>

<style lang="less">
.insurance-main {
  .date-range {
    .el-form-item__content {
      width: 65%;
      .el-date-editor.el-range-editor.el-input__inner.el-date-editor--daterange.el-range-editor--small {
        width: 100%
      }
    }
  }
  .not-bottom {
    margin-bottom: 0 !important;
  }
  .content {
    .el-tabs__header {
      margin: 0;
    }
    .el-upload {
      float: left
    }
    .el-upload-list {
      float: left;
      .el-upload-list__item {
        height: 22px !important;
        margin: 5px 8px !important;
        width: auto !important;
      }
    }
    // .el-upload.el-upload--picture-card {
    //   width: 70px;
    //   height: 70px;
    //   line-height: 70px;
    //   margin:0
    // }
    // .el-upload-list__item {
    //   width: 70px !important;
    //   height: 70px !important;
    //   margin: 0px 8px 0 0 !important;
    // }
    .el-tabs__content {
      max-height: 70vh;
      overflow-y: auto;
      padding-top: 10px;
    }
  }
  .el-form-item {
    margin-bottom: 8px
  }
}
</style>
